"use client";

import { useTheme } from "next-themes";
import { Toaster as Sonner, ToasterProps } from "sonner";
const Toaster = ({
  ...props
}: ToasterProps) => {
  const {
    theme = "system"
  } = useTheme();
  return <Sonner theme={theme as ToasterProps["theme"]} className="toaster group" style={{
    "--normal-bg": "var(--popover)",
    "--normal-text": "var(--popover-foreground)",
    "--normal-border": "var(--border)"
  } as React.CSSProperties} {...props} data-sentry-element="Sonner" data-sentry-component="Toaster" data-sentry-source-file="sonner.tsx" />;
};
export { Toaster };