'use client';

import { useParams, useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useData } from '@/components/context/data-context';
export default function DataFetcher() {
  const {
    data,
    setData
  } = useData();
  const {
    cid
  } = useParams<{
    cid: string;
  }>();
  const router = useRouter();
  useEffect(() => {
    const checkData = () => {
      if (!data && cid) {
        try {
          // Only redirect if we're not already on the base URL
          if (window.location.pathname !== `/${cid}` && !window.location.pathname.includes('success')) {
            router.push(`/${cid}`);
          }
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      }
    };
    void checkData();
  }, [data, cid, setData, router]);
  return <div data-testid="data-fetcher" data-sentry-component="DataFetcher" data-sentry-source-file="data-fetcher.tsx" />;
}